<template>
  <tabs
    ref="tabs"
    nav-class="nav-side"
    starting-tab="decision-a"
    :full-height="true"
    action-type="MultiDecision"
    @add-multidecision-group="addDecisionGroup"
  >
    <tab
      v-if="hasSuccessor('a')"
      id="decision-a"
      :position="1"
      icon="a"
      :tooltip="action.config.successorTitles['a']"
    >
      <decision
        ref="a"
        key="a"
        :action="action"
        :read-only="readOnly"
        :workflow="workflow"
        successor-key="a"
      />
    </tab>
    <tab
      v-if="hasSuccessor('b')"
      id="decision-b"
      :position="2"
      icon="b"
      :tooltip="action.config.successorTitles['b']"
    >
      <decision
        ref="b"
        key="b"
        :action="action"
        :read-only="readOnly"
        :workflow="workflow"
        successor-key="b"
      />
    </tab>
    <tab
      v-if="hasSuccessor('c')"
      id="decision-c"
      :position="3"
      icon="c"
      :tooltip="action.config.successorTitles['c']"
    >
      <decision
        ref="c"
        key="c"
        :action="action"
        :read-only="readOnly"
        :workflow="workflow"
        successor-key="c"
      />
    </tab>
    <tab
      v-if="hasSuccessor('d')"
      id="decision-d"
      :position="4"
      icon="d"
      :tooltip="action.config.successorTitles['d']"
    >
      <decision
        ref="d"
        key="d"
        :action="action"
        :read-only="readOnly"
        :workflow="workflow"
        successor-key="d"
      />
    </tab>
    <tab
      v-if="hasSuccessor('e')"
      id="decision-e"
      :position="5"
      icon="e"
      :tooltip="action.config.successorTitles['e']"
    >
      <decision
        ref="e"
        key="e"
        :action="action"
        :read-only="readOnly"
        :workflow="workflow"
        successor-key="e"
      />
    </tab>
  </tabs>
</template>
<script>
import Tabs from '@/components/TabSet'
import Tab from '@/components/Tab'

import Decision from '@/views/WorkflowEdit/ToolboxesLarge/MultiDecision/Decision'

import vClickOutside from 'v-click-outside'

export default {
  components: {
    Tabs,
    Tab,
    Decision
  },

  directives: {
    clickOutside: vClickOutside.directive
  },

  inject: ['workflowApi', 'contentApi', 'eventBus'],
  props: ['action', 'workflow', 'readOnly'],

  data() {
    return {
      successorCount: this.getSuccessorCount(),
      isLoading: false
    }
  },

  watch: {
    'action.successors': {
      handler() {
        this.successorCount = this.getSuccessorCount()
      }
    }
  },

  created() {
    this.eventBus.$on('multidecision-endpoint-update-finish', (id) => {
      if (id === this.action.id) {
        this.isLoading = false
        sessionStorage.removeItem(this.action.id + '-successors')
      }
    })

    this.eventBus.$on('multidecision-successor-change-disable-all', (id) => {
      if (id === this.action.id) {
        this.isLoading = true
      }
    })
  },

  beforeDestroy() {
    console.log('in multidecision largetool box close destroy')
    console.log({
      validators: this.action.validators,
      config: this.action.config
    })
    this.workflowApi.updateAction(this.action.id, {
      validators: this.action.validators,
      config: this.action.config
    })
  },

  methods: {
    hasSuccessor(key) {
      return this.action.successors.hasOwnProperty(key)
    },

    getSuccessorCount() {
      return Object.keys(this.action.successors).length
    },

    addDecisionGroup() {
      if (this.successorCount > 5) {
        return
      }

      if (!this.action.successors) {
        return
      }

      if (this.isLoading) {
        return
      }

      this.eventBus.$emit(
        'multidecision-successor-change-disable-all',
        this.action.id
      )

      let key = 'a'
      let titles = {}
      const successorsMap = {}

      // get last successor
      const elseSuccessor = this.action.successors.else

      for (let i = 0; i < Math.min(this.successorCount, 6); i++) {
        successorsMap[key] = this.action.successors[key] || ''
        titles[key] = this.action.config.successorTitles[key] || 'Untitled'
        key = String.fromCharCode(key.charCodeAt(0) + 1)
      }

      successorsMap.else = elseSuccessor

      this.workflowApi
        .updateAction(this.action.id, {
          successors: successorsMap,
          config: { successorTitles: titles }
        })
        .then(() => {
          const lastTab = [...this.$refs.tabs.orderedTabs].pop()
          this.$refs.tabs.activeTab = lastTab.id
        })
    }
  }
}
</script>
<style lang="sass"></style>
